// import { storeToRefs } from 'pinia';
import { createRouter, createWebHashHistory } from 'vue-router';
// import NotFound from '../views/NotFound.vue';
import { useDetailStore } from '../store/index';
// import SignInView from '../views/SignIn.vue';

// const authorizeIsAdmin = (to, from, next) => {
//   const detailStore = useDetailStore();
//   const { currentUser } = storeToRefs(detailStore);
//   if (currentUser && !(currentUser.role === 'admin' || currentUser.role === 'super')) {
//     next('/not-found');
//     return;
//   }

//   next();
// };

const routes = [
  {
    path: '/',
    name: 'home',
    // component: () => import('../views/ModelView.vue'),
    // component: () => import('../views/SampleTestView.vue'),
    redirect: '/simulation-test',
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/signin',
    name: 'sign-in',
    // component: SignInView,
    component: () => import('../views/SignIn.vue'),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: '/signup',
    name: 'sign-up',
    component: () => import('../views/SignUp.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/model-history',
    name: 'model-history',
    // props: true,
    // props: (route) => route.params,
    component: () => import('../views/ModelView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/user-info',
    name: 'user-info',
    // props: true,
    // props: (route) => route.params,
    component: () => import('../views/UserInfoView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/qna',
    name: 'qna',
    component: () => import('../views/QnA.vue'),
  },
  {
    path: '/add-customer',
    name: 'add-customer',
    // props: true,
    // props: (route) => route.params,
    component: () => import('../views/AddCustomerView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/add-product',
    name: 'add-product',
    // props: true,
    // props: (route) => route.params,
    component: () => import('../views/AddProductView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/sample-test-datasets',
    name: 'sample-test-datasets',
    // props: true,
    // props: (route) => route.params,
    component: () => import('../views/SampleTestDatasetsView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/sample-test',
    name: 'sample-test',
    // props: true,
    // props: (route) => route.params,
    component: () => import('../views/SampleTestView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    // for Guest  - Used
    path: '/simulation-test',
    name: 'simulation-test',
    // props: true,
    // props: (route) => route.params,
    component: () => import('../views/GuestSampleTestView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    // for Guest - Used
    path: '/simulation-image-test',
    name: 'simulation-image-test',
    // props: true,
    // props: (route) => route.params,
    component: () => import('../views/GuestImageSampleTestView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/setting-info',
    name: 'setting-info',
    component: () => import('../views/SettingView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/news-info',
    name: 'news-info',
    component: () => import('../views/NewsView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/IndexView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/radar-detail',
    name: 'radar-detail',
    component: () => import('../views/RadarView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/multiple-radar-detail',
    name: 'multiple-radar-detail',
    component: () => import('../views/MultipleRadarView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/single-item-report',
    name: 'single-item-report',
    // props: (route) => route.params,
    component: () => import('../views/SingleItemReportView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/sample-datasets',
    name: 'sample-datasets',
    component: () => import('../views/SampleDatasetsView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/TSG',
    name: 'TSG',
    component: () => import('../views/TSG.vue'),
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/formal-datasets',
    name: 'formal-datasets',
    component: () => import('../views/FormalDatasetsView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/explain-result/:modelId/:testItem',
    name: 'explain-result',
    component: () => import('../views/ExplainResultView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/total-result/:modelId',
    name: 'total-result',
    component: () => import('../views/TotalResultView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    path: '/ai-taiwan-show',
    name: 'ai-taiwan-show',
    // props: true,
    // props: (route) => route.params,
    component: () => import('../views/AITaiwanShowView.vue'),
    // meta: {
    //   keepAlive: false,
    // },
  },
  {
    // path: '/:pathMatch(.*)*',
    // path: '/:catchAll(.*)',
    path: '/:catchAll(.*)*',
    name: 'not-found',
    // component: () => import('../views/SignIn.vue'),
    redirect: '/',
    // meta: {
    //   requiresAuth: false,
    // },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // 從 localStorage 取出 token
  const localStorageToken = localStorage.getItem('token');
  const detailStore = useDetailStore();
  const { token } = detailStore;
  let { isAuthenticated } = detailStore;
  // console.log(`localStorageToken: ${localStorageToken}`);
  // console.log(`tokenInStore: ${token}`);
  // console.log(`isAuthenticated: ${isAuthenticated}`);

  // 有 token 的情況下，才向後端驗證
  if (localStorageToken && localStorageToken !== token) {
    // console.log('call detailStore.fetchCurrentUser');
    isAuthenticated = await detailStore.fetchCurrentUser;
  }

  // console.log(`2isAuthenticated: ${isAuthenticated}`);
  // eslint-disable-next-line max-len
  // const pathsWithoutAuthentication = ['sign-in', 'sign-up', 'sample-datasets', 'ai-taiwan-show', 'AboutUs', 'TSG', 'Privacy', 'qna', 'index'];
  const pathsWithoutAuthentication = ['sample-datasets', 'ai-taiwan-show', 'AboutUs', 'TSG', 'Privacy', 'qna', 'index'];

  // 如果 token 無效，且要去除了登入和註冊以外的其他頁面，則轉址到登入頁
  if (!isAuthenticated && !pathsWithoutAuthentication.includes(to.name)) {
    // console.log('11111111');
    // next('/signin');
    next('/index');
    return;
  }
  // console.log(`66666666 isAuthenticated: ${isAuthenticated}, to.name: ${to.name}`);

  // 如果 token 有效，且要去登入和註冊頁，則轉址到首頁
  // if (isAuthenticated && pathsWithoutAuthentication.includes(to.name)) {
  //   console.log('222222');
  //   next('/signup');
  //   return;
  // }

  next();
});

export default router;

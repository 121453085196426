import { apiHelper } from '../utils/helpers';

export default {
  getCurrentUser() {
    return apiHelper.get('/v1.0/oauth/get_current_user');
  },
  getUserInfo() {
    return apiHelper.get('/v1.0/user/info');
  },
  getSpecialGuestInfo() {
    return apiHelper.get('/v1.0/user/special-guest');
  },
  updateUserInfo({
    name,
    taxId,
    companyName,
    phone,
    department,
    secondEmail,
  }) {
    return apiHelper.put('/v1.0/user/info', {
      name,
      tax_id_number: taxId,
      company_name: companyName,
      phone,
      department,
      second_email: secondEmail,
    });
  },
};

<template>
  <router-view/>
</template>

<script>
// import emitter from './bus';

// const { EventHubConsumerClient } = require('@azure/event-hubs');

export default {
  name: 'App',
  created() {
    // this.receiveEventHubData();
  },
  methods: {
    // receiveEventHubData() {
    //   const connectionString = process.env.VUE_APP_EVENTHUB_CONNECTION_STRING;
    //   const eventHubName = process.env.VUE_APP_EVENTHUB_NAME;
    //   const consumerGroup = process.env.VUE_APP_CONSUMER_GROUP_NAME;

    //   const consumerClient = new EventHubConsumerClient(
    //     consumerGroup,
    //     connectionString,
    //     eventHubName,
    //   );
    //   this.subscription = consumerClient.subscribe({
    //     processEvents: async (events, context) => {
    //       // console.log(`events: ${JSON.stringify(events[0].body)}`);
    //       // this.$eventbus.$emit('telemetryEvent', events[0].body);
    //       emitter.emit('simulationEvent', events[0].body);
    //       await context.updateCheckpoint(events[events.length - 1]);
    //     },
    //     // processError: async (err, context) => {
    //     //   console.log(`Error: ${err}, context: ${context}`);
    //     // },
    //   });
    // },
  },
};
</script>

<style>

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

* {
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

</style>

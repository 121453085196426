import { defineStore } from 'pinia';
import usersAPI from '../apis/users';

// eslint-disable-next-line
export const useDetailStore = defineStore({
  id: 'detail',
  state: () => ({
    currentUser: {
      id: -1,
      account: '',
      view: '',
      execute: '',
      role: '',
    },
    token: '',
    isAuthenticated: false,
    detail: {},
    userId: '',
    datas: [],
    actionRunNumber: 0,
    radarDetail: {},
    isShowReport: false,
  }),
  getters: {
    getters: {
      currentUser: (state) => state.currentUser,
      isShowReport: (state) => state.isShowReport,
    },
  },
  actions: {
    setCurrentUser(currentUser) {
      this.currentUser = {
        ...this.currentUser,
        // 透過 API 取得的 currentUser
        ...currentUser,
      };

      this.isAuthenticated = true;
      this.token = localStorage.getItem('token');
      // console.log(`setCurrentUser currentUser: ${JSON.stringify(this.currentUser)}`);
    },
    revokeAuthentication() {
      this.currentUser = {};
      this.isAuthenticated = false;
      this.token = '';
      localStorage.removeItem('token');
    },
    async fetchCurrentUser() {
      try {
        const { data } = await usersAPI.getCurrentUser();
        console.log(`fetchCurrentUser: ${JSON.stringify(data)}`);
        if (data.status === 'error') {
          throw new Error(data.message);
        }

        const { id, account, role } = data;
        this.setCurrentUser({
          id,
          account,
          role,
        });

        return true;
      } catch (error) {
        console.error(error.message);
        this.revokeAuthentication();
        return false;
      }
    },
    changeIsShowReportStatus() {
      this.isShowReport = !this.isShowReport;
    },
  },
});
